import { SettingsService } from 'src/app/core/services/settings.service';
import { DashBoardContactsService } from './../../core/services/dashboard-contact.service';
import { Router } from '@angular/router';
import { ActionButtonInterface } from '../../core/interfaces/action-button.interface';
import { AuthService } from './../../core/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/core/auth/user.model';
import { environment } from 'src/environments/environment';
import { Subject, Subscription } from 'rxjs';
import { MatomoTracker } from '@ngx-matomo/tracker';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass']
})

export class DashboardComponent implements OnInit {
  dashboardReady = false;
  current_user:User;
  firstname:string = '';
  now:Date = new Date();
  hour:number;
  profile_picture_url:string = '';
  floatingActionIcon = '<i class="fa-solid fa-sharp fa-plus"></i>';
  searchResults: Subject<any> = new Subject<any>();
  $subscription1:Subscription;

  canShowCandidateWidget:boolean = false;
  canShowReminderWidget:boolean = false;
  canShowEmailsWidget:boolean = false;

  actionButtons:ActionButtonInterface[] = [
    {
      icon: '<i class="fa-solid fa-pen"></i>',
      callback: () => { this.createNewMail(); },
      title: 'entities.email'
    },
    {
      icon: '<i class="fa-solid fa-bell"></i>',
      callback: () => { this.createReminder(); },
      title: 'entities.reminder'
    }
  ]

  dashboardLayout:any;
  mailWidgetIndex:number;
  reminderWidgetIndex:number;
  candidateWidgetIndex:number;

  constructor(private authService: AuthService,private router: Router, private dashBoardContactsService: DashBoardContactsService, private settingsService : SettingsService,private readonly tracker: MatomoTracker) {
    this.hour = this.now.getHours();
  }

  ngOnInit(): void {
    this.dashboardLayout = this.settingsService.getSettings()['02dashboardLayout'].value;
    this.mailWidgetIndex = this.dashboardLayout.findIndex(item => {
      return item.component === 'app-mailwidget';
    });
    this.reminderWidgetIndex = this.dashboardLayout.findIndex(item => {
      return item.component === 'app-reminderwidget';
    });
    this.candidateWidgetIndex = this.dashboardLayout.findIndex(item => {
      return item.component === 'app-candidatewidget';
    });


    this.authService.user.subscribe(res => {
      this.current_user = res;
      if(res.id){
        this.profile_picture_url = environment.fileServiceDownload + '?ouid=' + this.current_user.id + '&class=UserPhoto' + '&sessionId=' + this.current_user.sessionId;
        this.firstname = (this.current_user.firstName) ? this.current_user.firstName : '';
        this.canShowCandidateWidget = this.current_user.modules.includes('Candidates');
        this.canShowReminderWidget = this.current_user.modules.includes('Reminders');
        this.canShowEmailsWidget = this.current_user.modules.includes('Emails');
        if(!this.canShowEmailsWidget){
          // Remove the email action from the actions if the user doesnt have the email module
          this.actionButtons = this.actionButtons.filter(item => {
            return item.title !== 'entities.email';
          });
        }
        // Remove the reminders action from the actions if the user doesnt have the reminders module
        if(!this.canShowReminderWidget){
          this.actionButtons = this.actionButtons.filter(item => {
            return item.title !== 'entities.reminder';
          });
        }
        this.dashboardReady = true;
      }
    })
  }

  createNewMail(){
    this.router.navigate(['/emails/compose']);
  }

  createReminder(){
    this.router.navigate(['/reminders/compose']);
  }

  onSingleActionClicked(event:MouseEvent){
    console.log(event);
  }

  onButtonClicked(event:Function){
    event();
    this.tracker.trackGoal(11);
  }

  onSearchInput(searchInput: string) {
    if(this.$subscription1){
      this.$subscription1.unsubscribe();
    }
    // There should be at least 2 characters
    if (searchInput.length > 2) {
      this.$subscription1 = this.dashBoardContactsService
        .getContactList(searchInput)
        .subscribe((resData) => {
          if (resData && resData.result.length) {
            this.searchResults.next({
              page: 1,
              items: this.addPhotoUrlToContacts(resData.result)
            });

            this.tracker.trackGoal(4);

          } else {
            this.searchResults.next([]);
          }
        });
    } else {
      this.searchResults.next([]);
    }
  }


  addPhotoUrlToContacts(inputList:any){
    var inputlistE = inputList;
    var sess = this.current_user.sessionId;
    inputlistE.forEach(function(contact){
      if(contact.Person.photoFileName){
        var imgClass = 'UserPhoto';
        if(contact.entity == 'Consultant'){
          imgClass = 'UserPhoto';
        }else if(contact.entity == 'Candidate'){
          imgClass = 'CandidatePhoto';
        }else if(contact.entity == 'RelationContact'){
          imgClass = 'RelationContactPhoto';
        }

        contact.logoUrl = environment.fileServiceDownload + '?ouid=' + contact.uid + '&class='+imgClass+'&sessionId=' + sess;
      }else{
        contact.logoUrl = null;
      }
    });
    return inputlistE;
  }

}
